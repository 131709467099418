.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  max-width: 600px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.question-text {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
  color: #333;
}

.answer-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.answer-options button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.answer-options button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.answer-options button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.answer-options button:active {
  background-color: #004085;
}

.answer-options button.correct {
  background-color: green;
}

.answer-options button.incorrect {
  background-color: red;
}
