.add-question-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
  min-height: calc(100vh - 70px); /* Ajustez cette valeur si nécessaire */
  box-sizing: border-box;
}

.add-question-form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.question-block {
  margin-bottom: 20px;
}

.question-block label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.answer-option {
  margin-bottom: 10px;
}

.correct-answer {
  display: inline-block;
  margin-left: 10px;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Ajout de marge pour espacer le bouton du formulaire */
}

.btn-submit:hover {
  background-color: #0056b3;
}
