.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.contact-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form .form-group textarea {
  resize: vertical;
  height: 100px;
}

.contact-form .btn-submit {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.contact-form .btn-submit:hover {
  background-color: #0056b3;
}

.contact-form p {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

.contact-form .success-message {
  color: green;
}

.contact-form .error-message {
  color: red;
}
