/* Navbar.css */
.navbar {
  background-color: #23395D !important;
  padding: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
}

.navbar-logo img {
  height: 70px;  /* Augmenter la taille du logo */
  margin-left: 2.5rem;
  margin-right: 1.5rem; /* Ajouter une marge à droite pour éloigner du bord */
}

.d-inline-block {
  margin-left: 1.5rem;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 24px;
  margin-right: 1.5rem; /* Ajouter une marge à droite pour éloigner du bord */
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #333;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #333;
}

.bm-item-list {
  color: #ffffff;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #ffffff;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #f3a333;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navbar-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.5rem;
}

a.nav-link{
  color: #FFFFFF;
  font-weight: bold;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

a.nav-link:hover{
  color: #FEC260;
}

.navbar-links li {
  margin: 0 10px;
  font-weight: bold;
}

.navbar-links a,
.navbar-links button {
  color: white;
  text-decoration: none;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.navbar-links a:hover,
.navbar-links button:hover {
  text-decoration: underline;
  background-color: #555;
  border-radius: 5px;
}

.logout-button {
  background-color: #ff4b4b;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d43f3f;
}

.navbar-toggler {
  margin-right: 1.5rem;
}

@media (max-width: 1050px) {
  .bm-burger-button {
    display: block;
  }

  .navbar-links.desktop {
    display: none;
  }

  .navbar-links.mobile {
    display: block;
  }
}

@media (min-width: 1051px) {
  .navbar-links.mobile {
    display: none;
  }

  .navbar-links.desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}
