.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-container p {
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .about-us-container h2 {
    color: #333;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .about-us-container .back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .about-us-container .back-home:hover {
    background-color: #0056b3;
  }

  h1, h2{
    text-align: center;
    margin-top: 4rem;
  }
  