.general-quiz-page {
  padding: 20px;
  text-align: center;
}

.category-section {
  margin-bottom: 40px;
}

.category-section h3::after {
  content: '▼';
  position: absolute;
  transform: translateY(-50%);
  font-size: 1.2em;
  color: #007BFF;
}

.category-section h3 {
  cursor: pointer;
  content: '▼';
  background-color: #f0f0f0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  transition: color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.category-section h3:hover {
  color: #007BFF;
  background-color: #e0e0e0;
  border: 1px solid #007BFF;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2), 0 6px 20px rgba(0, 123, 255, 0.19);
}

.quiz-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.quiz-card {
  flex: 1 1 300px;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  transition: transform 0.2s, box-shadow 0.3s ease;
  margin-top: 2rem;
}

.quiz-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.quiz-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.quiz-card h3 {
  font-size: 20px;
  margin: 15px 0;
}

.quiz-card p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.quiz-button {
  display: inline-block;
  margin: 15px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.quiz-button:hover {
  background-color: #0056b3;
}

h3 {
  font-size: 24px;
  margin-top: 2.5rem;
}

.quiz-card h2 {
  font-size: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  margin: 20px 0;
}

.general-quiz-page h1 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 700;
}