.quiz-slider {
  margin-top: 30px; /* Ajouter un espace sous la navbar */
  padding: 0 20px; /* Ajout d'un padding pour un meilleur espacement */
}

/* Styles pour l'image du quiz slider */
.quiz-slider img {
  width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 8px; /* Ajout d'un léger arrondi aux coins des images */
  transition: transform 0.3s ease, filter 0.3s ease; /* Transition pour l'effet de zoom */
}

.quiz-slider img:hover {
  transform: scale(1.1); /* Zoom sur l'image lors du survol */
  filter: brightness(1.2); /* Augmentation de la luminosité lors du survol */
}

/* Styles pour le message surprise */
.surprise-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Empêcher l'interaction avec le message */
}



.quiz-slider .custom-slider img {
  width: 100%;
  height: auto;
  max-height: 600px; /* Ajustez cette valeur selon vos besoins */
  object-fit: contain; /* Afficher l'image en entier sans la couper */
  border-radius: 8px; /* Ajout d'un léger arrondi aux coins des images */
}

.quiz-slider h3 {
  text-align: center;
  margin-top: 10px;
  font-size: 1.2em;
}

.slick-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-slider {
  margin-top: 20px; /* Ajouter un espace entre les deux sliders */
}



