.quiz-page-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.quiz-content {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.quiz-header img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.quiz-header h1 {
  font-size: 2em;
  margin-top: 40px;
}

.quiz-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.answer-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin: 10px 0;
}

.answer-button:hover {
  background-color: #3ab94d;
}

h1 {
  font-size: 4em;
  margin: 2.5rem;
}
