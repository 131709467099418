.admin-dashboard {
  padding: 20px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.create-quiz-button, .logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.create-quiz-button:hover, .logout-button:hover {
  background-color: #0056b3;
}

.logout-button {
  background-color: #dc3545;
}

.quiz-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.quiz-table th, .quiz-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.quiz-table th {
  background-color: #f2f2f2;
}

.edit-button, .delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #1d882b;
  color: white;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.create-admin-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9e109;
  color: rgb(9, 9, 9);
}

.create-admin-button:hover {
  background-color: #41ff08;
}
