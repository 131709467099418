* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden; /* Empêche le défilement horizontal */
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

/*.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}*/

.main-content {
  flex: 1;
  padding-top: 70px; /* Ajustez cette valeur selon la hauteur de votre navbar */
  margin: 5%;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-info {
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  color: black;
  font-size: 14px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px 0;
}

.slick-slide img {
  margin: auto;
}
