/* src/components/PrivacyPolicy.css */

.privacy-policy-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    text-align: center;
}

.privacy-policy-container h1 {
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.privacy-policy-container h2 {
    color: #555;
    margin-top: 20px;
    font-weight: bold;
}

.privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left; /* Pour aligner les listes à gauche tout en centrant le conteneur */
    display: inline-block; /* Pour que la liste soit centrée en tant que bloc */
}

.privacy-policy-container li {
    margin-bottom: 10px;
}

.privacy-policy-container p {
    margin-bottom: 20px;
    padding: 0 20px; /* Ajout de padding pour les paragraphes */
}

.privacy-policy-container p,
.privacy-policy-container ul {
    text-align: justify; /* Justifier le texte pour une meilleure lisibilité */
    display: inline-block; /* Pour que les paragraphes et listes soient centrés */
    max-width: 800px; /* Limite de la largeur pour une meilleure lisibilité */
}

h1 {
    font-size: 2em;
}
