.footer-info {
  text-align: center;
  padding: 50px 0;
  background-color: #ffffff;
  color: #333;
}

.text-create {
  margin: 0;
  font-size: 1rem; /* Ajustez cette valeur selon vos besoins */
}

.devsite{
  color: #0011ff;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.devsite:hover{
  color: #ff0000;
}
