#scroll_to_top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  #scroll_to_top a img {
    width: 2.5rem;
    height: auto;
    transition: opacity 0.3s;
  }
  
  #scroll_to_top a img:hover {
    opacity: 0.7;
  }
  
  .text-center {
    text-align: center;
  }
  
  .container {
    padding: 20px;
  }
  
  .ccm-cookie-declaration {
    margin-top: 20px;
  }
