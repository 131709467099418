.footer {
  background-color: #23395D !important;
  color: #fff;
  padding: 20px 0;
  position: fixed; /* Changez sticky en fixed */
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-button {
  color: #fff;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.footer-button:hover {
  background-color: #1f51b5;
}

h5 {
  margin: 10px;
}

.social-media {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.footer-liens {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

a {
  color: #fff;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 0.3rem;
}



p.text-create{
  color: #040404;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

