/* Home.css */
.home-container {
  color: #000000;
  text-align: center;
  padding: 50px 20px;
}

.home-container p {
  color: #000000;
  font-size: 20px;
}

.start-quiz-button {
  background-color: #3A9AD9;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-quiz-button:hover {
  background-color: #E35D6A;
}

@media (max-width: 600px) {
  h1.titrePri {
    font-size: 1.5rem !important;
    margin: 1rem !important;
  }
}

